@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");

@import "~aos/dist/aos.css";
@import "~react-day-picker/style.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
  }

  html {
    font-size: 14px;
  }

  @screen 2xl {
    html {
      font-size: 16px;
    }
  }

  /* // Typography */
  .h1 {
    @apply text-4xl font-extrabold leading-tight tracking-tighter;
  }

  .h2 {
    @apply text-3xl font-extrabold leading-tight tracking-tighter;
  }

  .h3 {
    @apply text-3xl font-bold leading-tight;
  }

  .h4 {
    @apply text-2xl font-bold leading-snug tracking-tight;
  }

  @screen md {
    .h1 {
      @apply text-5xl;
    }

    .h2 {
      @apply text-4xl;
    }
  }

  /* // Buttons */
  .btn,
  .btn-sm {
    @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
  }

  .btn {
    @apply px-8 py-3 shadow-lg;
  }

  .btn-sm {
    @apply px-4 py-2 shadow;
  }

  /* // Pulsing animation */
  @keyframes pulseLoop {
    0% {
      opacity: 0.15;
      transform: scale(1) translateZ(0);
    }
    30% {
      opacity: 0.15;
    }
    60% {
      opacity: 0;
    }
    80% {
      opacity: 0;
      transform: scale(1.8) translateZ(0);
    }
  }
  @keyframes pulseMiniLoop {
    0% {
      opacity: 0;
      transform: scale(1) translateZ(0);
    }
    30% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.3;
    }
    80% {
      opacity: 0;
      transform: scale(3) translateZ(0);
    }
  }
  .pulse {
    transform: scale(1);
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 10000ms linear infinite;
  }
  .pulse-mini {
    animation: pulseMiniLoop 6000ms linear infinite;
  }
  .pulse-1 {
    animation-delay: -3000ms;
  }
  .pulse-2 {
    animation-delay: -6000ms;
  }

  /* // Animations delay */
  .animation-delay-500 {
    animation-delay: 500ms !important;
  }

  .animation-delay-1000 {
    animation-delay: 1000ms !important;
  }

  .translate-z-0 {
    transform: translateZ(0);
  }

  /* Tooltip */
  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  .arrow::before {
    content: "";
    transform: rotate(45deg);
    background: #eaeaea;
  }

  .tooltip[data-popper-placement^="top"] > .arrow {
    bottom: -4px;
  }

  .tooltip[data-popper-placement^="bottom"] > .arrow {
    top: -4px;
  }

  .tooltip[data-popper-placement^="left"] > .arrow {
    right: -4px;
  }

  .tooltip[data-popper-placement^="right"] > .arrow {
    left: -4px;
  }

  /* // Custom AOS animations */
  [data-aos="zoom-y-out"] {
    transform: scaleX(1.03);
    opacity: 0;
    transition-property: transform, opacity;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@import "./toast.css";

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: #eaeaea;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #eaeaea;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}
